<template>
  <div id="lang">
    <!-- <navBar :title="$t('lang.LanguageChoice')" /> -->
    <van-nav-bar
      :title="$t('lang.LanguageChoice')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="langBox">
      <div
        :class="active == index ? 'lang active' : 'lang'"
        @click="clickLang(index)"
        v-for="(item, index) in lang"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: localStorage.getItem("langIndex") || 1, //选中的语言
      lang: [
        this.$t("lang.FamiliarStyle"),
        this.$t("lang.English"),
        this.$t("lang.ComplexFont"),
        this.$t("lang.ThaiLanguage"),
        this.$t("lang.SpanishLanguage"),
      ],
      langSimple: ["zh-cn", "en", "zh-hk", "th", "es"],
      langApi: ["zh", "en", "tw", "th", "es"],
    };
  },
  methods: {
    // 切换语言
    clickLang(index) {
      this.active = index;
      localStorage.setItem("lang", this.langSimple[index]);
      localStorage.setItem("langIndex", index);
      // 给首页接口的语言标识
      localStorage.setItem("apiLang", this.langApi[index]);
      this.$i18n.locale = this.langSimple[index];
      // location.reload();
      location.href = "/";
    },
  },
};
</script>

<style lang="scss" scoped>
#lang {
  .langBox {
    padding: 15px;
    .lang {
      font-size: 16px;
      line-height: 40px;
      height: 40px;
      margin-bottom: 10px;
      text-align: center;
      color: #333;
      background-image: linear-gradient(#fff, #e8e8e8);
      border: solid 1px #e5e5e5;
      -webkit-border-radius: 5px;
      border-radius: 5px;
    }
    .active {
      order: -1;
      background: #3c475c;
      color: #fff;
      border: solid 1px #3c475c;
    }
  }
}
</style>
